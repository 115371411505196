import * as React from "react"
import AnswerLayout from "../../../components/answer-layout/answer-layout"

const AnswerPageCorrect4 = () => {
  return (
    <AnswerLayout
      step="4"
      backgroundColor="pink"
      contentHTML="
        It doesn't matter if it was a random guess or a reasoned response because you are absolutely correct! The triple divergence is a big sign of a trend change, and Bitcoin was up 15% in a short period of time, so it did a reversal and went down. Your trade brought you a profit of <span><span id=&quot;balance-direction&quot;></span>$<span id=&quot;balance-change&quot;></span></span>. Good job!
      "
      buttonText="Continue"
      buttonLink="/question/5"
      balanceText="Your Balance:"
      images={{
        map: {
          show: true,
          factor: 0.04
        },
        washingtonTop: {
          show: false,
          factor: 0.08
        },
        dotsLeft: {
          show: true,
          factor: 0.12
        },
        chartDown: {
          show: true,
          factor: 0.16
        },
        dollarSmall: {
          show: true,
          factor: 0.2
        },
        bitcoin: {
          show: true,
          factor: 0.24
        },
        plusesHorizontal: {
          show: true,
          factor: 0.28
        },
        plusesHorizontalGreen: {
          show: false,
          factor: 0.28
        },
        washingtonBottom: {
          show: false,
          factor: 0.32
        },
        dollarBig: {
          show: true,
          factor: 0.36
        },
        plusesVertical: {
          show: true,
          factor: 0.4
        },
        plusesVerticalGreen: {
          show: false,
          factor: 0.4
        },
        dotsRight: {
          show: true,
          factor: 0.4
        },
        chartUp: {
          show: true,
          factor: 0.48
        },
        satoshiTop: {
          show: false,
          factor: 0.08
        },
        satoshiBottom: {
          show: false,
          factor: 0.32
        },
        bankTop: {
          show: false,
          factor: 0.08
        },
        bankBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenRedTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenRedBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowYellowTop: {
          show: true,
          factor: 0.08
        },
        chartArrowYellowBottom: {
          show: true,
          factor: 0.32
        },
        questionMarkTop: {
          show: false,
          factor: 0.12
        },
        questionMarkBottom: {
          show: false,
          factor: 0.32
        },
        chartGreenUp: {
          show: false,
          factor: 0.48
        },
        chartGreenDown: {
          show: false,
          factor: 0.16
        },
        bitcoinBottom: {
          show: false,
          factor: 0.36
        },
        confetti: {
          show: true,
          factor: 0.01
        }
      }}
    ></AnswerLayout>
  )
}

export default AnswerPageCorrect4